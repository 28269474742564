import React from "react";
import { useFetch } from "./useFetch";
import { useDataContext } from "./DataContextProvider";

export const UserList = ({ searchTxt }) => {
  const { data, handleChange } = useDataContext();
  const userList = useFetch(
    `${process.env.REACT_APP_API_BASE_URI}/api/Users/fetchAllUsers?subWard=${data["subWardGroup"]}`
  );

  return (
    <div className="form-group" id="recordGroup">
      {userList ? (
        userList?.length ? (
          userList
            .filter((u) =>
              u.MemberName?.toLowerCase().includes(searchTxt?.toLowerCase())
            )
            .map((user) => {
              console.log(
                data["recordGroup"],
                data["recordGroup"]?.find((u) => u.id === user.id)
              );
              return (
                <div className="record-item">
                  <input
                    id={`record_${user.id}`}
                    type="checkbox"
                    className="checkboxOption"
                    checked={data["recordGroup"]?.find((u) => u.id === user.id)}
                    onChange={() => {
                      if (data["recordGroup"]?.find((u) => u.id === user.id)) {
                        const newList = (data["recordGroup"] || []).filter(
                          (u) => u.id !== user.id
                        );
                        handleChange("recordGroup")(newList);
                      } else {
                        handleChange("recordGroup")([
                          ...(data["recordGroup"] || []),
                          user,
                        ]);
                      }
                    }}
                  />
                  <label
                    htmlFor={`record_${user.id}`}
                    className="record-container"
                  >
                    <div className="record-header-wrapper">
                      <img
                        src={
                          user.Member_Photo || require("./image/profile.jpeg")
                        }
                        alt="logo"
                      />
                      <div className="record-header-content">
                        {user.SWaCHId && (
                          <div className="info">
                            <strong>SWaCH ID:</strong> {user.SWaCHId}
                          </div>
                        )}
                        {user.MemberName && (
                          <div className="info">
                            <strong>Name:</strong> {user.MemberName}
                          </div>
                        )}
                        {user.Intake_Ward && (
                          <div className="info">
                            <strong>Ward:</strong> {user.Intake_Ward}
                          </div>
                        )}
                      </div>
                    </div>
                  </label>
                </div>
              );
            })
        ) : (
          <p style={{ textAlign: "center" }}>
            {"Record Not Found! or maybe Slum is not selected."}
          </p>
        )
      ) : (
        <p style={{ textAlign: "center" }}>{"Loading..."}</p>
      )}
    </div>
  );
};
