import React from "react";
import { useRememberMeContext } from "./RememberMeContextProvider";
import { useDataContext } from "./DataContextProvider";

export const RememberMe = ({ fieldName }) => {
  const { rememberMe, setRememberMe } = useRememberMeContext();
  const { data } = useDataContext();

  return (
    <div className="form-group remember-me">
      <input
        type="checkbox"
        id={fieldName}
        value={fieldName}
        checked={rememberMe[fieldName]}
        onChange={(e) => {
          localStorage.setItem(
            "rememberMe",
            JSON.stringify({
              ...rememberMe,
              [fieldName]: e.target.checked,
            })
          );
          setRememberMe(
            {
              ...rememberMe,
              [fieldName]: e.target.checked,
            },
            data
          );
        }}
      />
      <label htmlFor={fieldName}>Remember Choice</label>
    </div>
  );
};
