import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { getDefaultValues } from "./utils";
import { useRememberMeContext } from "./RememberMeContextProvider";

const DataContext = createContext({ members: [] });

export const useDataContext = () => useContext(DataContext);

const useFetchMembersData = () => {
  const [data, setData] = useState(getDefaultValues());
  const { rememberMe } = useRememberMeContext();

  const onRestData = useCallback(
    (onlyDefault = false) => setData(getDefaultValues(onlyDefault)),
    []
  );

  const handleChange = useCallback(
    (fieldName) => (value) => {
      if (rememberMe[fieldName]) {
        const oldData = getDefaultValues();
        localStorage.setItem(
          "userInputData",
          JSON.stringify({
            ...oldData,
            [fieldName]: value,
          })
        );
      }

      setData({
        ...data,
        [fieldName]: value,
      });
    },
    [data, rememberMe]
  );

  return useMemo(() => {
    return { data, setData, onRestData, handleChange };
  }, [data, handleChange, onRestData]);
};

const DataProvider = ({ children }) => {
  const data = useFetchMembersData();
  return <DataContext.Provider value={data}>{children}</DataContext.Provider>;
};

export default DataProvider;
