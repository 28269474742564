import React from "react";
import { useDataContext } from "./DataContextProvider";
import { useGeoCodeContext } from "./GeoCodeContextProvider";
import { useIndex } from "./hooks";
import { usePost } from "./Login";
import { useRememberMeContext } from "./RememberMeContextProvider";
import { getDefaultValues, calculateNext } from "./utils";

export const SubmitButton = () => {
  const { setRememberMe } = useRememberMeContext();
  const { data, setData } = useDataContext();
  const { setIndex } = useIndex();
  const { geoCode } = useGeoCodeContext();

  const { error, isLoading, makeRequest, makeRequestRest } = usePost(
    `${process.env.REACT_APP_API_BASE_URI}/api/Users/saveProperty`,
    (res) => {
      if (res.success) {
        alert("Property Mapped Successfully.");
        const savedData = getDefaultValues();

        const savedRememberMe = localStorage.getItem("rememberMe")
          ? JSON.parse(localStorage.getItem("rememberMe"))
          : {};
        setData(savedData);
        setRememberMe(savedRememberMe, savedData);
        setIndex(calculateNext(-1, savedRememberMe, savedData));
      } else {
        makeRequestRest();
      }
    }
  );

  return (
    <button
      id="submit"
      className="submit-btn"
      disabled={
        isLoading ||
        error ||
        !data["gateCollectionName"] ||
        (data["gateCollectionContactNumber"] &&
          data["gateCollectionContactNumber"]?.length !== 10)
      }
      onClick={() => {
        makeRequest({
          ...data,
          ...geoCode,
          recordGroup: data.recordGroup?.map((x) => x.id).join(","),
        });
      }}
    >
      Submit
    </button>
  );
};
