import React from "react";
import { useDataContext } from "./DataContextProvider";

export const ShiftSelection = () => {
  const { data, handleChange } = useDataContext();
  return (
    <div className="form-group form-button" id="shiftGroup">
      <div>
        {["Morning", "Evening"].map((field) => (
          <React.Fragment key={field}>
            <input
              id={field}
              type="radio"
              name="shiftGroup"
              className="radioOption"
              value={field}
              checked={data["shiftGroup"] === field}
              onChange={(e) => {
                handleChange("shiftGroup")(e.target.value);
              }}
            />
            <label htmlFor={field} className="button button-solid">
              {field}
            </label>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};
