import { createContext, useContext, useMemo, useState } from "react";

const DataContext = createContext({ members: [] });

export const useRememberMeContext = () => useContext(DataContext);

const useFetchRememberMeData = () => {
  const [rememberMe, setRememberMe] = useState(
    localStorage.getItem("rememberMe")
      ? JSON.parse(localStorage.getItem("rememberMe"))
      : {}
  );

  const onRestRememberMe = () => {
    setRememberMe({});
    localStorage.removeItem("rememberMe");
    localStorage.removeItem("userInputData");
  };

  const _setRememberMe = (value, data = {}) => {
    let persistData = {};
    Object.keys(value).map((key) => {
      if (value[key]) {
        persistData = {
          ...persistData,
          [key]: data[key],
        };
      }

      return key;
    });
    localStorage.setItem("userInputData", JSON.stringify(persistData));
    setRememberMe(value);
  };

  return useMemo(
    () => ({
      rememberMe,
      setRememberMe: _setRememberMe,
      onRestRememberMe,
    }),
    [rememberMe]
  );
};

const RememberMeProvider = ({ children }) => {
  const data = useFetchRememberMeData();
  return <DataContext.Provider value={data}>{children}</DataContext.Provider>;
};

export default RememberMeProvider;
