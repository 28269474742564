import React from "react";
import { useDataContext } from "./DataContextProvider";

export const SlumSelection = () => {
  const { data, handleChange } = useDataContext();
  return (
    <div className="form-group form-button" id="mapSlumGroup">
      <div>
        {["MAP SLUM", "MAP NON-SLUM"].map((field) => (
          <React.Fragment key={field}>
            <input
              id={field}
              type="radio"
              name="mapSlumGroup"
              className="radioOption"
              value={field}
              disabled={field === "MAP NON-SLUM"}
              checked={data["mapSlumGroup"] === field}
              onChange={(e) => {
                handleChange("mapSlumGroup")(e.target.value);
              }}
            />
            <label htmlFor={field} className="button">
              {field}
            </label>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};
