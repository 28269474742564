import React from "react";
import { useDataContext } from "./DataContextProvider";

export const GateCollection = () => {
  const { data, handleChange } = useDataContext();
  return (
    <>
      <div className="form-group form-button" id="gateCollectionPaymentGroup">
        <div>
          <h3>Mode of user fee payment</h3>
          {["Cheque", "Cash"].map((field) => (
            <React.Fragment key={field}>
              <input
                id={field}
                type="radio"
                name="gateCollectionPaymentGroup"
                className="radioOption"
                disabled={field === "Cheque"}
                value={field}
                checked={data["gateCollectionPaymentGroup"] === field}
                onChange={(e) => {
                  handleChange("gateCollectionPaymentGroup")(e.target.value);
                }}
              />
              <label htmlFor={field} className="button button-solid">
                {field}
              </label>
            </React.Fragment>
          ))}
        </div>
      </div>

      {data["wasteCollectionServiceGroup"] !== "Unallocated" && (
        <div className="form-group" id="gateCollectionFeeGroup">
          <h3>Monthly User Fee of waste picker</h3>
          <div className="form-number">
            <input
              type="number"
              min="0"
              step="1"
              id="gateCollectionFee"
              disabled={!data["gateCollectionPaymentGroup"]}
              value={data["gateCollectionFee"]}
              onChange={(e) =>
                handleChange("gateCollectionFee")(e.target.value)
              }
              onBlur={(e) => {
                const val = +e.target.value;
                if (val < 0 || val % 1) {
                  handleChange("gateCollectionFee")(0);
                }
              }}
            />
          </div>
        </div>
      )}

      <div className="form-group form-button" id="gateCollectionFeedbackGroup">
        <div>
          <h3>Service related feedback</h3>
          {["daily", "once in two days", "less than once in two days"].map(
            (field) => (
              <React.Fragment key={field}>
                <input
                  id={field}
                  type="radio"
                  name="gateCollectionFeedbackGroup"
                  className="radioOption"
                  value={field}
                  disabled={!data["gateCollectionFee"]}
                  checked={data["gateCollectionFeedbackGroup"] === field}
                  onChange={(e) => {
                    handleChange("gateCollectionFeedbackGroup")(e.target.value);
                  }}
                />
                <label htmlFor={field} className="button button-solid">
                  {field}
                </label>
              </React.Fragment>
            )
          )}
        </div>
      </div>
    </>
  );
};
