import React from "react";
import { useFetch } from "./useFetch";
import { useDataContext } from "./DataContextProvider";

export const SlumList = () => {
  const { data, handleChange } = useDataContext();
  const slumList = useFetch(
    `${process.env.REACT_APP_API_BASE_URI}/api/Users/fetchAllSlums?subWard=${data["subWardGroup"]}`
  );

  return (
    <div className="form-group form-button" id="slumNameGroup">
      <div>
        {slumList?.map((field) => (
          <React.Fragment key={field.name_by_report}>
            <input
              id={field.name_by_report}
              type="radio"
              name="slumNameGroup"
              className="radioOption"
              value={field.name_by_report}
              checked={data["slumNameGroup"] === field.name_by_report}
              onChange={(e) => {
                handleChange("slumNameGroup")(e.target.value);
              }}
            />
            <label
              htmlFor={field.name_by_report}
              className="button button-solid"
            >
              {field.name_by_report}
            </label>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};
