import { useState } from "react";
import { calculateNext, handleBack, handleNext } from "./utils";
import { useRememberMeContext } from "./RememberMeContextProvider";
import { useDataContext } from "./DataContextProvider";

export const useIndex = () => {
  const { rememberMe } = useRememberMeContext();
  const { data } = useDataContext(rememberMe);
  const [index, setIndex] = useState(calculateNext(-1, rememberMe, data));

  const onNext = () => setIndex(handleNext(index, rememberMe, data));
  const onBack = () => setIndex(handleBack(index, rememberMe, data));
  const onIndexRest = () => setIndex(handleNext(-1, {}, {}));

  return {
    index,
    setIndex,
    onNext,
    onBack,
    onIndexRest,
  };
};
