import { createContext, useContext, useEffect, useState } from "react";

const GeoCodeContext = createContext({ members: [] });

export const useGeoCodeContext = () => useContext(GeoCodeContext);

const useFetchGeoCodeData = () => {
  const [geoCode, setGeoCode] = useState();

  useEffect(() => {
    if (!navigator.geolocation) {
      alert("Geo Location sharing is not supported on your device.");
    }
    navigator.permissions.query({ name: "geolocation" }).then((stat) => {
      if (stat.state !== "granted") {
        alert("Please grant access to your geo location and reload the page.");
      }
    });
    navigator.geolocation.getCurrentPosition(
      (pos) => {
        const crd = pos.coords;
        setGeoCode({
          latitude: crd.latitude,
          longitude: crd.longitude,
        });
      },
      console.log,
      {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      }
    );
  }, []);

  return geoCode;
};

const GeoCodeProvider = ({ children }) => {
  const data = useFetchGeoCodeData();

  if (!data) {
    return;
  }

  return (
    <GeoCodeContext.Provider value={data}>{children}</GeoCodeContext.Provider>
  );
};

export default GeoCodeProvider;
