import { useCallback, useMemo, useState } from "react";
import { ReactComponent as SearchIcon } from "./image/search-icon.svg";
import React from "react";
import { Layout } from "./Layout";
import { UserList } from "./UserList";
import { SlumList } from "./SlumList";
import { SubWardList } from "./SubWardList";
import { SlumSelection } from "./SlumSelection";
import { ShiftSelection } from "./ShiftSelection";
import { UploadFileWasteSelection } from "./UploadFileWasteSelection";
import { DoorStepCollection } from "./DoorStepCollection";
import { GateCollection } from "./GateCollection";
import { GateCollectionContactDetails } from "./GateCollectionContactDetails";
import { RememberMe } from "./RememberMe";
import { useIndex } from "./hooks";
import { useRememberMeContext } from "./RememberMeContextProvider";
import { useDataContext } from "./DataContextProvider";
import { SubmitButton } from "./SubmitButton";

const useLayoutProps = () => {
  const [searchTxt, setSearchTxt] = useState("");
  const { rememberMe, onRestRememberMe } = useRememberMeContext();

  const { data, onRestData } = useDataContext(rememberMe);
  const { index, onNext, onBack, onIndexRest } = useIndex(rememberMe, data);

  const onRest = useCallback(() => {
    onRestData(true);
    onRestRememberMe();
    onIndexRest();
  }, [onIndexRest, onRestData, onRestRememberMe]);

  const layoutProps = useMemo(
    () => ({
      0: {
        onNext,
        onRest,
        hideHeader: true,
        resetClassName: "icon-refresh-header",
        disabledNext: !data["mapSlumGroup"],
        children: (
          <div id="sign-up-form">
            <img src={require("./image/logo.png")} alt="Poornachakra Cart" />
            <div id="mapSlumWarpper">
              <RememberMe fieldName="mapSlumGroup" showRememberMe />
              <SlumSelection />
            </div>
          </div>
        ),
      },
      1: {
        onNext,
        onRest,
        onBack,
        disabledNext: !data["subWardGroup"],
        id: "subWardGroup",
        title: "Select sub-ward",
        showRememberMe: true,
        children: <SubWardList />,
      },
      2: {
        onNext,
        onRest,
        onBack,
        disabledNext: !data["slumNameGroup"],
        id: "slumNameGroup",
        title: "Select slum name",
        showRememberMe: true,
        children: <SlumList />,
      },
      3: {
        onNext,
        onRest,
        onBack,
        disabledNext: !data["recordGroup"]?.length,
        id: "recordGroup",
        showRememberMe: true,
        resetClassName: "icon-refresh-record-group",
        renderSubHeader: (
          <div className="top-bar">
            <input
              type="text"
              value={searchTxt}
              onChange={(e) => setSearchTxt(e.target.value)}
              placeholder="Search..."
            />
            <SearchIcon />
          </div>
        ),
        children: <UserList searchTxt={searchTxt} />,
      },
      4: {
        onNext,
        onRest,
        onBack,
        disabledNext: !data["shiftGroup"],
        id: "shiftGroup",
        title: "Select shift",
        showRememberMe: true,
        children: <ShiftSelection />,
      },
      5: {
        onNext,
        onRest,
        onBack,
        disabledNext: !data["wasteCollectionServiceGroup"],
        id: "wasteCollectionServiceGroup",
        children: <UploadFileWasteSelection />,
      },
      6: {
        onNext,
        onRest,
        onBack,
        disabledNext: !(
          +data["doorStepResidential"] + +data["doorStepCommercial"]
        ),

        id: "doorStepCollectionwrapper",
        children: <DoorStepCollection />,
      },
      7: {
        onNext,
        onRest,
        onBack,
        disabledNext: !data["gateCollectionFeedbackGroup"],
        id: "gateCollectionPaymentwrapper",
        children: <GateCollection />,
      },
      8: {
        onBack,
        title: "Contact details",
        onRest,
        children: (
          <>
            <GateCollectionContactDetails />
            <SubmitButton />
          </>
        ),
      },
    }),
    [data, onBack, onNext, onRest, searchTxt]
  );

  return useMemo(() => {
    return layoutProps[index];
  }, [index, layoutProps]);
};

const Dashboard = () => {
  const layoutProps = useLayoutProps();

  return <Layout {...layoutProps} />;
};

export default Dashboard;
