import React from "react";
import { useDataContext } from "./DataContextProvider";

export const DoorStepCollection = () => {
  const { data, handleChange } = useDataContext();
  return (
    <>
      <div className="form-group" id="doorStepPropertyGroup">
        <h3>Property Count:</h3>
        <div className="form-number" id="doorStepResidentialGroup">
          <label htmlFor="residential">Residential</label>
          <input
            type="number"
            min="0"
            step="1"
            id="doorStepResidential"
            value={data["doorStepResidential"]}
            onChange={(e) =>
              handleChange("doorStepResidential")(e.target.value)
            }
            onBlur={(e) => {
              const val = +e.target.value;
              if (val < 0 || val % 1) {
                handleChange("doorStepResidential")(0);
              }
            }}
          />
        </div>
        <div className="form-number" id="doorStepCommercialGroup">
          <label htmlFor="commercial">commercial</label>
          <input
            type="number"
            min="0"
            step="1"
            id="doorStepCommercial"
            value={data["doorStepCommercial"]}
            onChange={(e) => handleChange("doorStepCommercial")(e.target.value)}
            onBlur={(e) => {
              const val = +e.target.value;
              if (val < 0 || val % 1) {
                handleChange("doorStepCommercial")(0);
              }
            }}
          />
        </div>
      </div>
      <div className="form-group form-button" id="doorStepManagedGroup">
        <div className="form-group" id="doorStepDropOutPropertyGroup">
          <h3>Property Status:</h3>
          {data["wasteCollectionServiceGroup"] !== "Allocated But Unserviced" &&
            data["wasteCollectionServiceGroup"] !== "Unallocated" && (
              <div className="form-number" id="doorStepDropOutGroup">
                <label htmlFor="doorStepDropOut">
                  Drop out
                  <br />
                  मेम्बर जातात पन नागरिक देत नाही
                </label>
                <input
                  type="number"
                  min="0"
                  step="1"
                  id="doorStepDropOut"
                  value={data["doorStepDropOut"]}
                  max={
                    +data["doorStepResidential"] + +data["doorStepCommercial"]
                  }
                  onChange={(e) => {
                    handleChange("doorStepDropOut")(e.target.value);
                  }}
                  onBlur={(e) => {
                    const val = +e.target.value;
                    if (val < 0 || val % 1) {
                      handleChange("doorStepDropOut")(0);
                    }

                    const maxCount =
                      +data["doorStepResidential"] +
                      +data["doorStepCommercial"];

                    if (maxCount < +data["doorStepDropOut"]) {
                      handleChange("doorStepDropOut")("");
                    }
                  }}
                />
              </div>
            )}
          <div className="form-number" id="doorStepClosedGroup">
            <label htmlFor="closed">
              closed
              <br />
              प्रॉपर्टी बंद आहे
            </label>
            <input
              type="number"
              min="0"
              step="1"
              id="closed"
              max={
                +data["doorStepResidential"] +
                +data["doorStepCommercial"] -
                +data["doorStepDropOut"]
              }
              value={data["doorStepClosedGroup"]}
              onChange={(e) =>
                handleChange("doorStepClosedGroup")(e.target.value)
              }
              onBlur={(e) => {
                const val = +e.target.value;
                if (val < 0 || val % 1) {
                  handleChange("doorStepClosedGroup")(0);
                }

                const maxCount =
                  +data["doorStepResidential"] +
                  +data["doorStepCommercial"] -
                  +data["doorStepDropOut"];

                if (maxCount < +data["doorStepClosedGroup"]) {
                  handleChange("doorStepClosedGroup")("");
                }
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};
