import * as React from "react";
import { Routes, Route, useSearchParams } from "react-router-dom";
import { RequireAuth } from "./AuthProvider";
import Login from "./Login";
import Dashboard from "./Dashboard";
import DataProvider from "./DataContextProvider";
import RememberMeProvider from "./RememberMeContextProvider";
import GeoCodeProvider from "./GeoCodeContextProvider";

export default function App() {
  const [searchParams] = useSearchParams();
  const [visible, setVisible] = React.useState(false);

  React.useEffect(() => {
    console.log("bypass", searchParams.get("userId"));
    if (searchParams.get("userId")) {
      localStorage.setItem("photo_link", searchParams.get("photoLink"));
      localStorage.setItem("user_id", searchParams.get("userId"));
      localStorage.setItem("Swach_Logged_In", true);
      localStorage.setItem("isLoggedInUsingQueryParams", true);
    }
  }, [searchParams]);

  React.useEffect(() => {
    setTimeout(() => {
      setVisible(true);
    }, 3000);
  }, []);

  if (!visible) {
    return <p style={{ textAlign: "center" }}>{"Loading..."}</p>;
  }

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <RequireAuth>
              <GeoCodeProvider>
                <RememberMeProvider>
                  <DataProvider>
                    <Dashboard />
                  </DataProvider>
                </RememberMeProvider>
              </GeoCodeProvider>
            </RequireAuth>
          }
        />
        <Route path="/login" element={<Login />} />
      </Routes>
    </>
  );
}
